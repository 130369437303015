import styled from "styled-components";
import { device } from "../../styles/sizes";

export const BreakfastStyle = styled.div`
  height: 50%;
	width: 200px;
	position: absolute;
	clip-path: polygon(100% 0, 50% 100%, 0 0);
	transform-origin: bottom;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 18px;
	left: 29.3%;

  @media ${device.mobile} {
    font-size: 10px;
    width: 150px;
    left: 25%;
  }
`

export const Inner = styled.div`
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media ${device.mobile} {
    span {
      max-width: 85px;
    }
  }
`

export const Image = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 8px;

  @media ${device.mobile} {
    width: 28px;
    height: 28px;
  }
`