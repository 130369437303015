import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};
`

export const BreakfastWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
  max-width: 340px;
  padding: 24px;
  border-radius: 24px;
`

export const BreakfastImage = styled.img`
  width: 140px;
  height: 140px;
`

export const BreakfastTitle = styled.div`
  font-size: 24px;
  margin-top: 16px;
  text-align: center;

  b {
    font-size: 28px;
  }
`