import styled from "styled-components";

export const ButtonStyle = styled.button`
  border: 0;
  background: ${({ theme }) => theme.colors.button};
  padding: 16px 24px;
  min-width: 300px;
  border-radius: 24px;
  font-size: 24px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 32px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.gray}
  }
`;