import React from 'react'
import { Button } from '..';
import * as s from './SelectedBreakfast.styled';
import { IBreakfastDegrees } from '../../types/data';
import { getTextColor } from '../../utils';

type SelectedBreakfastProps = {
  onReset: () => void,
  breakfast: IBreakfastDegrees
}


export const SelectedBreakfast: React.FC<SelectedBreakfastProps> = ({ onReset, breakfast }) => {
  return (
    <s.Container>
      <s.BreakfastWrapper
        initial={{ scale: 0, rotate: -1000 }}
        animate={{ scale: 1, rotate: 0 }}
        transition={{ duration: 1 }}
        style={{ background: breakfast.color }}
      >
        <s.BreakfastImage
          src={require(`../../assets/breakfast/${breakfast.icon}`)}
          alt={breakfast.name}
        />
        <s.BreakfastTitle style={{ color: getTextColor(breakfast.color) }}>
          Мы будем кушац <b>{breakfast.name}</b>
        </s.BreakfastTitle>
      </s.BreakfastWrapper>
      <Button onClick={onReset}>Заново</Button>
    </s.Container>
  )
}
