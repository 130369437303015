import React, { FC } from 'react'
import * as s from './Breakfast.styled'
import { IBreakfastDegrees } from '../../types/data'
import { getTextColor } from '../../utils'

type TBreakfastProps = {
  breakfast: IBreakfastDegrees,
}

export const Breakfast: FC<TBreakfastProps> = ({ breakfast }) => {

  return (
    <s.BreakfastStyle style={{
      background: breakfast.color,
      transform: `rotate(${breakfast.degrees}deg)`
    }}>
      <s.Inner style={{ color: getTextColor(breakfast.color) }}>
        <span>{breakfast.name}</span>
        <s.Image
          src={require(`../../assets/breakfast/${breakfast.icon}`)}
          alt={breakfast.name}
        />
      </s.Inner>
    </s.BreakfastStyle>
  )
}
