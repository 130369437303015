import React, { useEffect, useState } from 'react';
import { SelectedBreakfast, SpinWheel } from './components';
import { AppStyle } from './App.styled';
import { IBreakfastDegrees } from './types/data';
import { isMobile } from './utils';

function App() {
  const [selectedBreakfast, setSelectedBreakfast] = useState<IBreakfastDegrees | null>(null)

  useEffect(() => {
    if (isMobile()) {
      // @ts-ignore
      window.screen.orientation?.lock?.('portrait')
    }
  }, [])

  return (
    <AppStyle>
      <SpinWheel onFinish={setSelectedBreakfast} />
      {selectedBreakfast &&
        <SelectedBreakfast
          breakfast={selectedBreakfast}
          onReset={() => setSelectedBreakfast(null)} />}
    </AppStyle>
  );
}

export default App;
