import React from 'react'
import * as s from './Button.styled'

type ButtonProps = {
  children: React.ReactNode,
  disabled?: boolean,
  onClick?: () => void
}

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <s.ButtonStyle {...props}>{children}</s.ButtonStyle>
  )
}
