import React, { useRef, useState } from 'react'
import { Arrow, Breakfast, Button, Wheel } from '..'
import { getRandomArbitrary } from '../../utils';
import { IBreakfastDegrees } from '../../types/data';
import { breakfastData } from '../../data/breakfast';

type SpinWheelProps = {
  onFinish: (item: IBreakfastDegrees) => void
}

export const SpinWheel: React.FC<SpinWheelProps> = ({ onFinish }) => {
  const [isSpinning, setSpinning] = useState(false)
  const wheelRef = useRef<HTMLDivElement>(null);

  const spin = () => {
    if (!wheelRef.current) return;
    setSpinning(true)

    const container = wheelRef.current

    container.style.transition = 'none';
    container.style.transform = 'rotate(0deg)';

    const randomBreakfast = breakfastData[getRandomArbitrary(0, breakfastData.length)]

    container.style.transition = '2s';
    const deg = Math.round((getRandomArbitrary(5, 25) * 360) - randomBreakfast.degrees + 90);
    container.style.transform = "rotate(" + deg + "deg)";

    setTimeout(() => {
      container.style.transition = '8s'
    }, 2000);


    setTimeout(() => {
      setSpinning(false)
      onFinish(randomBreakfast)
    }, 4000);
  }
  return (
    <>
      <Button disabled={isSpinning} onClick={spin}>{isSpinning ? 'Вращаем...' : 'Вращать'}</Button>
      <div style={{ position: 'relative' }} onClick={(e) => console.log(e)}>
        <Wheel ref={wheelRef}>
          {breakfastData.map((bf) => <Breakfast
            key={bf.name}
            breakfast={bf}
          />)}
        </Wheel>
        <Arrow isAnimated={isSpinning} />
      </div>
    </>
  )
}
