export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export function randomiseArray<T>(paramArray: T[]): T[] {
  let array = [...paramArray]
  let buffer = [],
    start;

  for (let i = array.length; i >= array.length && i > 0; i--) {
    start = Math.floor(Math.random() * array.length);
    buffer.push(array.splice(start, 1)[0]);
  }

  return buffer;
}

export const getTextColor = (hexcolor: string) => {
  hexcolor = hexcolor.replace('#', '');
  let r = parseInt(hexcolor.substr(0, 2), 16);
  let g = parseInt(hexcolor.substr(2, 2), 16);
  let b = parseInt(hexcolor.substr(4, 2), 16);
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 160 ? '#19232D' : 'white';
};

export const getRandomArbitrary = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
}

export function isMobile() {
  //@ts-ignore
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (
    /windows phone/i.test(userAgent) ||
    /android/i.test(userAgent) ||
    //@ts-ignore
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  ) {
    return true;
  }

  return false;
}