import styled from "styled-components";
import { device } from "../../styles/sizes";

export const WheelStyle = styled.div`
  width: 500px;
	height: 500px;
	background-color: #ccc;
	border-radius: 50%;
	border: 8px solid #dde;
	position: relative;
	overflow: hidden;
  position: relative;

  @media ${device.mobile} {
    width: 320px;
    height: 320px;
  }
`