import { createGlobalStyle } from "styled-components";

export const theme = {
  colors: {
    dark: "#333",
    white: '#eee',
    background: "#34495e",
    button: "#18A999",
    gray: '#D3C4D1'
  }
};

export const GlobalStyles = createGlobalStyle`
  #root {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.background};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
  }
`;
