import { IBreakfast, IBreakfastDegrees } from "../types/data";
import { getRandomColor, randomiseArray } from "../utils";

export const breakfastList: IBreakfast[] = [
  {
    name: 'Омлет',
    icon: 'omelette.png'
  },
  {
    name: 'Каша',
    icon: 'oatmeal.png'
  },
  {
    name: 'Кукурузные хлопья',
    icon: 'cereal.png'
  },
  {
    name: 'Блинчики',
    icon: 'pancakes.png'
  },
  {
    name: 'Салат',
    icon: 'salad.png'
  },
  {
    name: 'Бутерброды',
    icon: 'sandwich.png'
  },
  {
    name: 'Сырники',
    icon: 'cheese pancake.png'
  },
  {
    name: 'Ничего',
    icon: 'sad.png'
  }
]

const prepareBreakfastList = (): IBreakfastDegrees[] => {
  return randomiseArray(breakfastList).map((elem, index, array) => ({
    ...elem,
    degrees: Math.round((360 / array.length) * index),
    color: getRandomColor()
  }))
}

export const breakfastData = prepareBreakfastList()