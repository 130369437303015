import React, { forwardRef } from 'react'
import * as s from './Wheel.styled';

type TWheel = {
  children: React.ReactNode,
  style?: React.CSSProperties
}

export const Wheel = forwardRef<HTMLDivElement, TWheel>(({ children, style }, ref) => {
  return (
    <s.WheelStyle ref={ref} style={style}>{children}</s.WheelStyle>
  )
})
