import React from 'react'
import * as s from './Arrow.styled'

type ArrowProps = {
  isAnimated: boolean
}

export const Arrow: React.FC<ArrowProps> = ({ isAnimated }) => {
  return (
    <s.ArrowStyle isAnimated={isAnimated} />
  )
}
