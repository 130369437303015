import styled from "styled-components";

export const ArrowStyle = styled.div<{ isAnimated: boolean }>`
  position: absolute;
  background: ${({ theme }) => theme.colors.dark};
  width: 30px;
  height: 30px;
  right: -10px;
  top: calc(50% - 15px);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  ${({ isAnimated }) => isAnimated && `animation: 0.6s ease-in-out 0s 10 normal none running pinWheel`}
`